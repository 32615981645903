import ApiService from "@/services/ApiService";

import { getIsValidPhone, getPhoneTextFull } from "@/utils/phoneUtils";
import { getIsValidEmail, getEmailTextFull } from "@/utils/emailUtils";

const DirectorWriteMixin = {
  data() {
    return {
      itemData: {},

      itemId: "",
      name: "",
      jobgrade: "",
      job: "",
      acountState: {
        name: "acountStateRadio",
        dataList: [
          { id: "A", text: "활성화" },
          { id: "I", text: "비활성" },
          { id: "D", text: "휴면" },
        ],
        currentStatus: "A",
      },
      authorityState: {
        name: "authorityStateRadio",
        dataList: [
          { id: "ADMIN", text: "관리자" },
          { id: "INSPECT", text: "검수 담당자" },
        ],
        currentStatus: "ADMIN",
      },
      emailState: {
        name: "emailStateRadio",
        dataList: [
          { id: true, text: "수신" },
          { id: false, text: "미수신" },
        ],
        currentStatus: true,
      },
      alarmState: {
        name: "alarmStateRadio",
        dataList: [
          { id: true, text: "수신" },
          { id: false, text: "미수신" },
        ],
        currentStatus: true,
      },
      email: {
        name: "",
        domain: "",
      },
      tel: {
        phone0: "02",
        phone1: "",
        phone2: "",
      },
      mobile: {
        phone0: "",
        phone1: "",
        phone2: "",
      },
      regDate: "",
      lastDate: "",
    };
  },
  methods: {
    async save(isModifyMode, path) {
      const obj = this.getObjToSave();

      let result = null;

      if (isModifyMode) {
        result = await ApiService.shared.putData(path, obj);
      } else {
        result = await ApiService.shared.postData(path, obj);
      }

      if (result.code == "200") {
        const refreshPath = `${this.$routerPath.SYSTEM_DIRECTOR_MODIFY}/${result.data.managerNum}`;
        window.location.reload();
        // this.$router.push({
        //   path: refreshPath,
        // });

        //this.alert('저장 되었습니다.');

        // this.$router.push({
        //   path: this.$routerPath.SYSTEM_DIRECTOR_LIST
        // });
      } else {
        this.alert(result.text);
      }

      if (result.data) {
        this.onCompleteSave(result.data);
      }
    },
    onCompleteSave(obj) {},
    getAlertMessageCommon() {
      const matchRole = this.authorityState.dataList.filter(
        (item) => item.id == this.authorityState.currentStatus,
      );
      // if (matchRole.length == 0 && this.acountState.currentStatus == 'A')
      //   return '권한을 확인해주세요.';

      if (!this.name) return "담당자명을 확인해주세요.";
      if (!this.jobgrade) return "부서명/직급을 확인해주세요.";
      if (!this.job) return "담당 업무를 확인해주세요.";
      if (!this.email.name || !this.email.domain) return "이메일을 확인해주세요.";

      if (!getIsValidPhone(this.tel)) return "전화번호를 확인해주세요.";
      // if( !this.tel.phone0 || !this.tel.phone1 || !this.tel.phone2 )  return '전화번호를 확인해주세요.';

      return null;
    },
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.SYSTEM_DIRECTOR_LIST,
        query: this.$route.query,
      });
    },
    getObjToSave() {
      const obj = {
        job: this.job,
        jobgrade: this.jobgrade,
        loginId: this.itemId,
        // "loginPass": "1",
        name: this.name,
        // "role": "ADMIN",
        emailYn: this.emailState.currentStatus,
        alarmYn: this.alarmState.currentStatus,
        role: this.authorityState.currentStatus,
        status: this.acountState.currentStatus,
      };

      if (getIsValidEmail(this.email)) {
        obj.email = getEmailTextFull(this.email);
      }

      if (getIsValidPhone(this.tel)) {
        obj.tel = getPhoneTextFull(this.tel);
      }
      if (getIsValidPhone(this.mobile)) {
        obj.mobile = getPhoneTextFull(this.mobile);
      }

      const ret = Object.assign({}, this.itemData, obj);

      return ret;
    },
  },
};
export default DirectorWriteMixin;
